import type { IStatus } from "@domain/model/status.interface";
import { Icon } from "@infrastructure/components/interface/icons/Icon";
import { getActiveStatus } from "@infrastructure/services/status/status.service";
import { Box, MenuItem, Select } from "@mui/material";
import { t } from "i18next";
import { useEffect, useState } from "react";

type Props = {
	status: Array<IStatus>;
	defaultValue: string;
	onChangeStatus: any;
};

const SelectStatus = (props: Props) => {
	const { status, defaultValue, onChangeStatus } = props;

	const [activeStatus, setActiveStatus] = useState<IStatus>(getActiveStatus(defaultValue));

	useEffect(() => {
		setActiveStatus(getActiveStatus(defaultValue));
	}, [defaultValue]);

	const handleChange = (value: string) => {
		setActiveStatus(getActiveStatus(value));
	};

	return (
		<Select
			labelId="status-label"
			id="status"
			value={activeStatus.value}
			onChange={(event) => {
				handleChange(event.target.value);
				onChangeStatus(event.target.value);
			}}
			fullWidth
			sx={{
				"& .circle": {
					display: "none",
				},
				bgcolor: activeStatus.bgColor,
				color: activeStatus.fontColor,
			}}
		>
			{status.map((item, index) => {
				return (
					<MenuItem key={index} value={item.value}>
						<Box sx={{ color: item.bgColor }} pr={1.25} className="circle">
							<Icon prefix="fas" iconName="circle" size="1x" />
						</Box>
						{t(item.label)}
					</MenuItem>
				);
			})}
		</Select>
	);
};

export default SelectStatus;
