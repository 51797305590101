import Chips from "@application/Components/participants/details/roleTags/Chips";
import type { DtoRole } from "@key4-front-library/core";
import { Grid, Stack } from "@mui/material";

type Props = {
	roles: Array<DtoRole>;
};

const Tags = (props: Props) => {
	const { roles } = props;
	const manualRoles = roles.filter((role) => role.type === "manual");
	const programmeRoles = roles.filter((role) => role.type === "programme");
	const externalRoles = roles.filter((role) => role.type === "external");
	const queryRoles = roles.filter((role) => role.type === "query");
	const sortByTypeArray = [];

	if (manualRoles.length > 0) {
		sortByTypeArray.push(manualRoles);
	}
	if (programmeRoles.length > 0) {
		sortByTypeArray.push(programmeRoles);
	}
	if (externalRoles.length > 0) {
		sortByTypeArray.push(externalRoles);
	}
	if (queryRoles.length > 0) {
		sortByTypeArray.push(queryRoles);
	}

	return (
		<Grid
			container
			sx={{
				display: "flex",
				flexDirection: "column",
			}}
		>
			<Stack spacing={2} width={"100%"}>
				{sortByTypeArray.map((element, i) => {
					return <Chips key={i} rolesByType={element} />;
				})}
			</Stack>
		</Grid>
	);
};

export default Tags;
