import type { DtoRole } from "@key4-front-library/core/Dto";
import { PaperTitle, Typography } from "@mykey4/core";
import { t } from "i18next";
import Tags from "./details/roleTags/Tags";

interface PropsParticipantRoleCard {
	roles: Array<DtoRole>;
}

const ParticipantRolesCard = (props: PropsParticipantRoleCard) => {
	const { roles } = props;

	return (
		<PaperTitle title={{ children: <Typography variant="h4">{t("old.registration.participantDetails.roles")}</Typography> }}>
			<Tags roles={roles} />
		</PaperTitle>
	);
};

export default ParticipantRolesCard;
