import { EMenuPath, type IDivider, type IMenuItem } from "@key4-front-library/core";
import { metaEnv } from "@mykey4/core";
export type Menu = "dashboard" | "participants" | "privileges" | "settings" | "advancedSettings" | "users" | "divider" | "support";

export const MenuItems: Record<Menu, IMenuItem | IDivider> = {
	dashboard: {
		translation: `old.registration.navigation.${EMenuPath.DASHBOARD}`,
		path: EMenuPath.DASHBOARD,
		icon: {
			prefix: "far",
			iconName: "chart-line-up",
		},
	},
	participants: {
		translation: `old.registration.navigation.${EMenuPath.PARTICIPANTS}`,
		path: EMenuPath.PARTICIPANTS,
		icon: {
			prefix: "far",
			iconName: "user",
		},
	},
	privileges: {
		translation: `old.registration.navigation.${EMenuPath.PRIVILEGES}`,
		path: EMenuPath.PRIVILEGES,
		icon: {
			prefix: "far",
			iconName: "gift",
		},
	},
	settings: {
		translation: `old.registration.navigation.${EMenuPath.SETTINGS}`,
		path: EMenuPath.SETTINGS,
		icon: {
			prefix: "far",
			iconName: "gear",
		},
	},
	advancedSettings: {
		translation: "registration.navigation.advancedSettings",
		path: EMenuPath.ADVANCED_SETTINGS,
		icon: {
			prefix: "far",
			iconName: "screwdriver-wrench",
		},
	},
	users: {
		translation: `old.registration.navigation.${EMenuPath.USERS}`,
		path: EMenuPath.USERS,
		icon: {
			prefix: "far",
			iconName: "users",
		},
	},
	support: {
		translation: `old.registration.navigation.${EMenuPath.SUPPORT}`,
		path: EMenuPath.SUPPORT,
		icon: {
			prefix: "far",
			iconName: "headset",
		},
		externalLink: metaEnv.myKey4.endPoint.support,
		target: "_blank",
	},
	divider: { type: "divider" },
};
