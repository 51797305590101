import { Services, queryFilters } from "@key4-front-library/core";
import type { DtoSearchFilterGet, DtoSearchFilterPost, DtoSearchFilterPut } from "@key4-front-library/core/Dto";
import { EnumSearchFilterScope } from "@key4-front-library/core/Enums";
import { FilteringOperator } from "@mykey4/core";

/**
 * Get list of availables SearchFilters
 * @param clientId Current client ID
 * @param eventId Current event ID
 * @typedef DtoSearchFilterGet Dto of SearchFilter
 * @returns List of SearchFilters
 */
const getList = async (clientId: string, eventId: string): Promise<Array<DtoSearchFilterGet>> =>
	await Services.Events.Registration.SearchFiltersService.getList(clientId, eventId, [
		...queryFilters(`scope${FilteringOperator.Equal}${EnumSearchFilterScope.PARTICIPANT}`),
	]);

/**
 * Create a new SearchFilter
 * @param clientId Current client ID
 * @param eventId Current event ID
 * @param searchFilter SearchFilter to create
 * @typedef DtoSearchFilterPost Dto of SearchFilter
 * @returns ID of SearchFilter created
 */
const create = async (clientId: string, eventId: string, searchFilter: DtoSearchFilterPost) =>
	await Services.Events.Registration.SearchFiltersService.post(clientId, eventId, searchFilter);

/**
 * Update a SearchFilter
 * @param clientId Current client ID
 * @param eventId Current event ID
 * @param searchFilterId Current SearchFilter ID
 * @param searchFilter SearchFilter to update
 * @typedef DtoSearchFilterPut Dto of SearchFilter to Post
 * @returns Success boolean
 */
const update = async (clientId: string, eventId: string, searchFilterId: string, searchFilter: DtoSearchFilterPut) =>
	await Services.Events.Registration.SearchFiltersService.put(clientId, eventId, searchFilterId, searchFilter);

/**
 * Delete a SearchFilter
 * @param clientId Current client ID
 * @param eventId Current event ID
 * @param searchFilterId Current SearchFilter ID
 * @returns Success boolean
 */
const deleteEntity = async (clientId: string, eventId: string, searchFilterId: string) =>
	await Services.Events.Registration.SearchFiltersService.deleteEntity(clientId, eventId, searchFilterId);

/**
 * Get list of Definition
 * @param eventId Current event ID
 * @returns Success boolean
 */
const getListDefinitions = async () => await Services.Registration.RegistrationService.getListDefinitions();

/**
 * SearchFilter Controller
 */
const SearchFilterController = {
	getList,
	create,
	update,
	deleteEntity,
	getListDefinitions,
};

export default SearchFilterController;
