import { ButtonCancel, ButtonSave } from "@infrastructure/components/interface/uikit/K4Button";
import { Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel, Stack, TextField } from "@mui/material";
import { t } from "i18next";
import { useEffect, useState } from "react";

interface Props {
	isOpen: boolean;
	onCloseClick: () => void;
	onSubmitClick: (titleQuery: string, isRoleQuery: boolean) => void;
	translation: any;
}

const SaveNewQueryModal = (props: Props) => {
	const { isOpen = false, onCloseClick, onSubmitClick, translation } = props;

	const translations = {
		buttons: {
			save: t("old.form.buttons.save"),
			cancel: t("old.form.buttons.cancel"),
		},
		checkbox: {
			saveAsRoleQuery: t("old.form.checkbox.save-as-role-query"),
		},
	};

	useEffect(() => {
		if (!isOpen) {
			setTitleQuery(undefined);
		}
	}, [isOpen]);

	const [titleQuery, setTitleQuery] = useState<string>();
	const [isRoleQuery, setIsRoleQuery] = useState<boolean>(false);

	return (
		<Dialog open={isOpen} maxWidth={"md"} fullWidth>
			<DialogTitle>{translation.searchFilter.modalNewQuery.title}</DialogTitle>
			<DialogContent>
				<Stack mt={".5em"}>
					<TextField
						fullWidth
						error={titleQuery === ""}
						id="outlined-error-helper-text"
						defaultValue={titleQuery}
						helperText={titleQuery === "" ? translation.searchFilter.modalNewQuery.emptyField : ""}
						placeholder={translation.searchFilter.modalNewQuery.placeholder}
						onChange={(event) => {
							setTitleQuery(event.target.value);
						}}
					/>
				</Stack>
			</DialogContent>
			<DialogActions
				sx={{
					padding: "24px",
				}}
			>
				<Stack direction={"row"} spacing=".8rem" width="100%" justifyContent="space-between">
					<FormControlLabel
						control={
							<Checkbox
								checked={isRoleQuery}
								onChange={() => {
									setIsRoleQuery(!isRoleQuery);
								}}
								inputProps={
									{
										"aria-label": "role-query",
									} as any
								}
							/>
						}
						label={translations.checkbox.saveAsRoleQuery}
					/>
					<Stack direction={"row"} spacing=".8rem">
						<ButtonCancel label={translations.buttons.cancel} onClick={onCloseClick} />
						<ButtonSave
							disabled={titleQuery === "" || !titleQuery}
							label={translations.buttons.save}
							onClick={() => {
								onSubmitClick(titleQuery ?? "", isRoleQuery);
							}}
						/>
					</Stack>
				</Stack>
			</DialogActions>
		</Dialog>
	);
};
export default SaveNewQueryModal;
