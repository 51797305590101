import { Core, DefaultQueryClient } from "@mykey4/core";
import { getRegistrationTranslation } from "@translations";
import ReactDOM from "react-dom/client";
import App from "./App";

const rootElement = document.getElementById("root");

if (rootElement) {
	ReactDOM.createRoot(rootElement).render(
		<Core queryClient={DefaultQueryClient} queryDebug={true} moduleTranslation={getRegistrationTranslation()} paletteMode={"light"}>
			<App />
		</Core>,
	);
}
