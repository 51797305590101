import { useParticipant } from "@api";
import { useContextModule } from "@key4-front-library/core";
import { Grid, GridLabel, PaperTitle, Stack, Typography } from "@mykey4/core";
import { t } from "i18next";
import { useParams } from "react-router-dom";

export const GeneralSectionParticipant = (): React.ReactNode => {
	const component = "generalSectionParticipant";

	const { participantId = "" } = useParams();
	const { client, event } = useContextModule();

	const participantQuery = useParticipant(client.id, event.id, participantId);

	if (!participantQuery.data) {
		return undefined;
	}

	return (
		<Stack>
			<PaperTitle
				title={{
					icon: { variant: "h4", prefix: "fal", name: "clipboard-list", color: "primary.light" },
					children: <Typography variant="h4">{t(`${component}.title`)}</Typography>,
				}}
			>
				<Grid container spacing={2}>
					<GridLabel label={t(`${component}.firstname`)}>
						<Typography>{participantQuery.data.firstname ?? <br />}</Typography>
					</GridLabel>
					<GridLabel label={t(`${component}.lastname`)}>
						<Typography>{participantQuery.data.lastname ?? <br />}</Typography>
					</GridLabel>
					<GridLabel label={t(`${component}.email`)}>
						<Typography>{participantQuery.data.email ?? <br />}</Typography>
					</GridLabel>
				</Grid>
			</PaperTitle>
		</Stack>
	);
};
