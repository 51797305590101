import { MAIN_TAB } from "@key4-front-library/core";
import type { TabProps } from "@mykey4/core";
import { t } from "i18next";

export const getParticipantDetailsTabs = (): Array<TabProps> => [
	{
		label: t("participantDetails.tab.general"),
		to: MAIN_TAB,
	},
	{
		label: t("participantDetails.tab.mails"),
		to: "mail",
	},
	{
		label: t("participantDetails.tab.faculty"),
		to: "faculty",
	},
];
