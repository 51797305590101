import { type DtoFacultyGetDetails, Services } from "@key4-front-library/core";

const getFacultyDetails = async (clientId: string, eventId: string, participantId: string): Promise<DtoFacultyGetDetails> => {
	return await Services.Events.Programme.FacultiesService.getDetails(clientId, eventId, participantId);
};

const FacultyController = {
	getFacultyDetails,
};

export default FacultyController;
