import DevToolsHelpers from "@key4-front-library/core/DevToolsHelpers";
import { DEFAULT_TIMEZONE } from "@mykey4/core";
import { Routes } from "@routes";
import { Settings } from "luxon";
import { version } from "../package.json";

const App = () => {
	// TODO: Remove when using the events' timezones
	Settings.defaultZone = DEFAULT_TIMEZONE;

	return (
		<>
			<DevToolsHelpers.VersionDevTools version={version} />
			<Routes />
		</>
	);
};

export default App;
