import { type ParticipantRead, getParticipant } from "@api";
import { API_VERSION_QUERY_PARAM, QUERY_KEYS_REGISTRATION, type UseQueryArgs, overrideQueryParams } from "@mykey4/core";
import { type UseQueryResult, useQuery } from "@tanstack/react-query";

export const useParticipant = (
	clientId: string,
	eventId: string,
	participantId: string,
	args: UseQueryArgs<ParticipantRead> = {},
): UseQueryResult<ParticipantRead> => {
	const { queryKeyPart = [], queryStrings, ...others } = args;
	return useQuery({
		queryKey: [...QUERY_KEYS_REGISTRATION.participants, ...queryKeyPart, "Get", clientId, eventId, participantId, queryStrings],
		queryFn: ({ signal }) =>
			getParticipant({
				clientId,
				eventId,
				participantId,
				queryStrings: overrideQueryParams(queryStrings, [{ key: API_VERSION_QUERY_PARAM, value: "1.0" }]),
				signal,
			}),
		...others,
	});
};
