import SaveModal from "@infrastructure/components/interface/modals/SaveModal";
import { presetColorPickerColors } from "@infrastructure/services/color/color.service";
import type { DtoRole } from "@key4-front-library/core";
import { Box } from "@mui/material";
import { t } from "i18next";
import { useCallback, useEffect, useRef, useState } from "react";
import { HexColorPicker } from "react-colorful";
import { type Control, useController } from "react-hook-form";

const styles = {
	picker: { display: "flex", position: "relative" },
	swatch: {
		width: "56px",
		height: "56px",
		borderRadius: "8px",
		border: "1px solid #bbb",
		cursor: "pointer",
	},
	popover: {
		top: " calc(100% + 2px)",
		left: 0,
		borderRadius: "9px",
		marginTop: "16px",
	},
	pickerSwatches: {
		display: "flex",
		justifyContent: "center",
		padding: "12px",
		flexWrap: "wrap",
	},
	pickerSwatch: {
		width: "24px",
		height: "24px",
		margin: "4px",
		border: "1px solid #bbb",
		padding: 0,
		borderRadius: "4px",
		cursor: "pointer",
		outline: "none",
	},
};

interface Props {
	color: string;
	setColor: (value: string) => void;
	control: Control<DtoRole>;
}

const PopoverPicker = (props: Props) => {
	const { color, setColor, control } = props;
	const popover = useRef<HTMLDivElement>(null);
	const [isOpen, setIsOpen] = useState(false);
	const [initialColor, setInitialColor] = useState("");
	const { field } = useController({ control, name: "color" });

	useEffect(() => {
		setInitialColor(color);
	}, [color]);

	const handleModaleClose = useCallback(() => {
		setIsOpen(false);
	}, []);
	const handleOpen = () => {
		setIsOpen(true);
	};

	const chooseColorTitle = t("old.registration.settings.roles.edition.chooseColorTitle");

	return (
		<Box sx={styles.picker} alignItems={"center"}>
			<Box style={{ backgroundColor: color }} onClick={handleOpen} sx={styles.swatch} />

			<SaveModal
				isOpen={isOpen}
				title={chooseColorTitle}
				handleModaleClose={() => {
					setColor(initialColor);
					handleModaleClose();
				}}
				handleSubmit={() => {
					setInitialColor(color);
					handleModaleClose();
				}}
				maxWidth={"xs"}
				isFullWidth={false}
			>
				<Box ref={popover} sx={styles.popover}>
					<HexColorPicker
						color={color}
						onChange={(color) => {
							field.onChange(color); // data send back to hook form
							setColor(color); // update textfield with color in parent component
						}}
					/>
					<Box sx={styles.pickerSwatches} width={"200px"}>
						{presetColorPickerColors.map((presetColor, i) => (
							<Box
								key={i}
								sx={styles.pickerSwatch}
								style={{ background: presetColor }}
								onClick={() => {
									field.onChange(presetColor); // data send back to hook form
									setColor(presetColor);
								}}
							/>
						))}
					</Box>
				</Box>
			</SaveModal>
		</Box>
	);
};

export default PopoverPicker;
