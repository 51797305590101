import "../../../../queryBuilder.scss";

import { uniqueId } from "lodash";
import { useEffect, useRef, useState } from "react";
import { useSearchParams } from "react-router-dom";

import { EFormAction } from "@domain/interfaces/form.interface";
import { ApplicationColors } from "@key4-front-library/core";
import K4QueryBuilder from "@key4-front-library/core/Bo/Components/QueryBuilder";
import type { DtoSearchFilterGet } from "@key4-front-library/core/Dto";
import { treeToSieve } from "@key4-front-library/core/Helpers";
import { Autocomplete, Collapse, Fab, IconButton, InputBase, Paper, Stack, TextField, Typography } from "@mui/material";
import { type Fields, type ImmutableTree, Utils as QbUtils } from "@react-awesome-query-builder/ui";

import { Icon } from "../icons/Icon";
import ConfirmationModal from "../modals/ConfirmationModal";
import SaveNewQueryModal from "./SaveNewQueryModal";

interface SearchListProps {
	translation: any;
	onSearchChange: (text: string) => void;
	onQueryBuilderChange: (text: string) => void;
	fields: Fields;
	searchFilterQueriesList: Array<DtoSearchFilterGet>;
	onCreateQuerySearchFilterClick: (titleQuery: string, isRoleQuery: boolean, queryBuilderTree: string) => Promise<void>;
	openSaveNewQueryModale: boolean;
	onOpenSaveNewQueryModaleClick: (openSaveNewQueryModale: boolean) => void;
	onUpdateQueryFilterClick: (querySearchFilter: DtoSearchFilterGet) => Promise<void>;
	onDeleteQueryFilterClick: (id: string) => Promise<void>;
	newQueryFilterId: string | null;
}

export const SearchList = (props: SearchListProps) => {
	const {
		translation,
		onSearchChange,
		onQueryBuilderChange,
		fields,
		searchFilterQueriesList,
		onCreateQuerySearchFilterClick,
		openSaveNewQueryModale,
		onOpenSaveNewQueryModaleClick,
		onUpdateQueryFilterClick,
		onDeleteQueryFilterClick,
		newQueryFilterId,
	} = props;

	const [search, setSearch] = useState("");
	const [isOpenQueryBuilder, setIsOpenQueryBuilder] = useState(false);
	const queryBuilderTreeRef = useRef<string>("");
	const [currentQuerySearchFilter, setCurrentQuerySearchFilter] = useState<DtoSearchFilterGet | null>(null);
	const [importQuerySearchFilter, setImportQuerySearchFilter] = useState<string>();
	const [openDeleteConfirmationQueryModale, setOpenDeleteConfirmationQueryModale] = useState<boolean>(false);

	const [searchUrlParams] = useSearchParams();

	const resetSearch = () => {
		onSearchChange("");
		setSearch("");
	};

	const resetQueryBuilder = () => {
		onQueryBuilderChange("");
		queryBuilderTreeRef.current = "";
		setImportQuerySearchFilter(importQuerySearchFilter ? undefined : "");
	};

	const handleToggleSearchClick = () => {
		setIsOpenQueryBuilder(!isOpenQueryBuilder);
		setCurrentQuerySearchFilter(searchFilterQueriesList.length > 0 ? searchFilterQueriesList[0] : null);
		setImportQuerySearchFilter("");
		resetQueryBuilder();
		if (search !== "") {
			resetSearch();
		}
	};

	const handleQueryChange = (tree: ImmutableTree) => {
		const queryString = treeToSieve(QbUtils.getTree(tree)) ?? null;
		onQueryBuilderChange(queryString ?? "");
		queryBuilderTreeRef.current = JSON.stringify(QbUtils.getTree(tree));
	};

	useEffect(() => {
		if (newQueryFilterId) {
			const query = searchFilterQueriesList.find((el) => el.id === newQueryFilterId);
			if (query) {
				setCurrentQuerySearchFilter(query);
			}
		}
	}, [newQueryFilterId, searchFilterQueriesList]);

	useEffect(() => {
		if (searchUrlParams.has("query") && searchUrlParams.get("query")?.length) {
			setIsOpenQueryBuilder(true);
		}
	}, []);

	return (
		<Paper variant="outlined">
			<Stack direction={"row"} sx={{ mx: 1.5, my: isOpenQueryBuilder ? 1.5 : 0 }}>
				{isOpenQueryBuilder ? (
					<Stack direction="row" alignItems="center" width="100%">
						<Typography variant={"h5"} color="primary">
							{translation.title}
						</Typography>
						<Stack ml="auto">
							<Fab size="small" aria-label="actions" color={"primary"} onClick={handleToggleSearchClick}>
								<Icon prefix="far" iconName="minus" />
							</Fab>
						</Stack>
					</Stack>
				) : (
					<>
						<InputBase
							id={uniqueId()}
							onChange={(e) => {
								setSearch(e.target.value);
								onSearchChange(e.target.value);
							}}
							placeholder={translation.placeholderSimpleSearch}
							inputProps={{
								"aria-label": "search-input",
							}}
							fullWidth
							value={search}
						/>
						<IconButton aria-label="reset-search" color={"secondary"} onClick={resetSearch}>
							<Icon prefix="far" iconName="xmark" />
						</IconButton>

						<Stack justifyContent="center">
							<Fab size="small" aria-label="actions" color={"primary"} onClick={handleToggleSearchClick}>
								<Icon prefix="far" iconName="plus" />
							</Fab>
						</Stack>
					</>
				)}
			</Stack>
			<Collapse in={isOpenQueryBuilder} unmountOnExit>
				<Stack>
					<Paper elevation={0}>
						<Stack ml={1.5} mr={0.5}>
							<Stack direction={"row"} alignItems={"center"}>
								<Autocomplete
									sx={{ marginTop: ".5rem" }}
									disablePortal
									options={searchFilterQueriesList}
									fullWidth
									getOptionLabel={(option) => option.name ?? ""}
									renderInput={(params) => (
										<TextField
											{...params}
											label={translation.searchFilter.autoComplete.title}
											inputProps={{
												...params.inputProps,
											}}
										/>
									)}
									renderOption={(optionProps, option: any) => {
										return (
											<li {...optionProps}>
												<Stack direction={"row"} justifyContent={"space-between"} sx={{ width: "100%" }}>
													{option.name}
													{option.id && option.isRoleQuery && <Icon iconName={"user-group-crown"} prefix={"far"} color={ApplicationColors.green.main} />}
												</Stack>
											</li>
										);
									}}
									isOptionEqualToValue={(option, value) => option.id === value.id}
									onChange={(_, query) => {
										setCurrentQuerySearchFilter(query ?? null);
										setImportQuerySearchFilter(query?.value ?? undefined);
										return query ? query.id : null;
									}}
									value={currentQuerySearchFilter ?? null}
								/>
								<Stack sx={{ margin: ".5rem" }} direction={"row"}>
									{!currentQuerySearchFilter?.id && (
										<Fab
											disabled={!currentQuerySearchFilter}
											onClick={() => {
												if (currentQuerySearchFilter && !currentQuerySearchFilter.id) {
													onOpenSaveNewQueryModaleClick(true);
												}
											}}
											size="small"
											aria-label="actions"
											color={"primary"}
										>
											<Icon prefix="far" iconName="plus" />
										</Fab>
									)}
									{currentQuerySearchFilter && currentQuerySearchFilter.id && (
										<Fab
											onClick={() => {
												onUpdateQueryFilterClick({
													...currentQuerySearchFilter,
													value: queryBuilderTreeRef.current,
												});
											}}
											size="small"
											aria-label="actions"
											color={"secondary"}
										>
											<Icon prefix="fal" iconName="save" />
										</Fab>
									)}
									<Stack ml={".5rem"}>
										<Fab
											disabled={!currentQuerySearchFilter?.id}
											onClick={() => {
												setOpenDeleteConfirmationQueryModale(true);
											}}
											size="small"
											aria-label="actions"
											color={"error"}
										>
											<Icon prefix="far" iconName="trash" />
										</Fab>
									</Stack>
								</Stack>
							</Stack>
							<K4QueryBuilder
								fields={fields}
								onQueryChange={(tree) => {
									handleQueryChange(tree);
								}}
								importQuerySearchFilter={importQuerySearchFilter}
							/>
						</Stack>
					</Paper>
					<SaveNewQueryModal
						isOpen={openSaveNewQueryModale}
						onCloseClick={() => {
							onOpenSaveNewQueryModaleClick(false);
						}}
						onSubmitClick={(titleQuery: string, isRoleQuery: boolean) => onCreateQuerySearchFilterClick(titleQuery, isRoleQuery, queryBuilderTreeRef.current)}
						translation={translation}
					/>
					<ConfirmationModal
						open={openDeleteConfirmationQueryModale}
						action={EFormAction.DELETE}
						handleModaleClose={() => {
							setOpenDeleteConfirmationQueryModale(false);
						}}
						handleAction={() => {
							setCurrentQuerySearchFilter(searchFilterQueriesList[0]);
							onDeleteQueryFilterClick(currentQuerySearchFilter!.id);
							resetQueryBuilder();
							setOpenDeleteConfirmationQueryModale(false);
						}}
						maxWidth={"sm"}
					/>
				</Stack>
			</Collapse>
		</Paper>
	);
};
