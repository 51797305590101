import { t } from "i18next";
import { useEffect, useState } from "react";

import ParticipantDetails from "@application/Configurations/participantDetails.configuration";
import { invitationStatus } from "@application/Configurations/status.configuration";
import FacultyController from "@application/Controllers/FacultyController";
import { Card, DataGridOld, type DtoFacultyGetDetails, type DtoFacultySession, getColumns, useContextModule } from "@key4-front-library/core";
import type { DataGridCellBulletColorProps } from "@key4-front-library/core/Bo/Components/DataGrid/components/cell/DataGridCellBulletColor";
import type { DtoFacultyGetChair, DtoFacultySessionPresentation, DtoFacultySessionPresentationSpeaker } from "@key4-front-library/core/Dto/FacultyDto";
import { useParams } from "react-router-dom";

export interface InvitationRow {
	id: string;
	session: DtoFacultySession;
	presentation: DtoFacultySessionPresentation | null;
	invitationType: string | null | undefined;
	status: DataGridCellBulletColorProps;
}

const ParticipantDetailInvitations = () => {
	const { participantId = "" } = useParams();
	const [invitations, setInvitations] = useState<Array<InvitationRow>>([]);
	const [faculty, setFaculty] = useState<DtoFacultyGetDetails>();
	const { client, event } = useContextModule();

	const getChairRowInformations = (uniqueKey: string, session: DtoFacultySession, chair: DtoFacultyGetChair) => {
		return {
			id: uniqueKey,
			session,
			presentation: null,
			invitationType: chair.quality ? chair.quality.label : "Chair",
			status: {
				label: t(invitationStatus.find((invitationStatus) => invitationStatus.value === chair.invitationStatus)?.label!),
				iconColor: invitationStatus.find((invitationStatus) => invitationStatus.value === chair.invitationStatus)?.bgColor ?? "",
			},
		};
	};

	const getSpeakerRowInformations = (
		uniqueKey: string,
		session: DtoFacultySession,
		presentation: DtoFacultySessionPresentation,
		speaker: DtoFacultySessionPresentationSpeaker,
	) => {
		return {
			id: uniqueKey,
			session,
			presentation,
			invitationType: speaker.quality ? speaker.quality.label : "Speaker",
			status: {
				label: t(invitationStatus.find((invitationStatus) => invitationStatus.value === speaker.invitationStatus)?.label!),
				iconColor: invitationStatus.find((invitationStatus) => invitationStatus.value === speaker.invitationStatus)?.bgColor ?? "",
			},
		};
	};

	const getTranslatedColumnLabels = (): Array<string> => {
		const headersNames: Array<string> = [];
		ParticipantDetails.invitationsDatagridHeaders.forEach((header) =>
			headersNames.push(t(`old.registration.participantDetails.tabs2.faculty.datagrid.headerLabels.${header.field}`)),
		);
		return headersNames;
	};

	const getParticipantDetails = async () => {
		const facultyData = await FacultyController.getFacultyDetails(client.id, event.id, participantId);
		setFaculty(facultyData);
	};

	useEffect(() => {
		if (faculty) {
			const rows: Array<InvitationRow> = [];
			faculty.sessions?.forEach((session: DtoFacultySession, sessionIndex: number) => {
				session.chairs?.forEach((chair: DtoFacultyGetChair, chairIndex: number) => {
					if (chair.participantId === faculty.participantId) {
						const uniqueKey = `chair-${sessionIndex}-${chairIndex}`;
						rows.push(getChairRowInformations(uniqueKey, session, chair));
					}
				});

				session.presentations?.forEach((presentation: DtoFacultySessionPresentation, presentationIndex: number) => {
					presentation.speakers?.forEach((speaker: DtoFacultySessionPresentationSpeaker, speakerIndex: number) => {
						if (speaker.participantId === faculty.participantId) {
							const uniqueKey = `presentation-${sessionIndex}-${presentationIndex}-${speakerIndex}`;
							rows.push(getSpeakerRowInformations(uniqueKey, session, presentation, speaker));
						}
					});
				});
			});
			setInvitations(rows);
		}
	}, [faculty]);

	useEffect(() => {
		getParticipantDetails();
	}, []);

	return (
		<Card sx={{ m: 0 }}>
			<DataGridOld
				sx={{ mt: 1 }}
				rowHeight={95}
				density={"standard"}
				columns={getColumns(getTranslatedColumnLabels(), ParticipantDetails.invitationsDatagridHeaders)}
				rows={invitations}
				isAutoHeight={true}
				isHideFooter={true}
			/>
		</Card>
	);
};

export default ParticipantDetailInvitations;
