import { t } from "i18next";
import { useRef, useState } from "react";

import { ApplicationColors } from "@application/Configurations/color.configuration";
import { Icon } from "@infrastructure/components/interface/icons/Icon";
import useClickOutside from "@infrastructure/services/hooks/useClickOutside";
import type { DtoProfile } from "@key4-front-library/core";
import { ListItem } from "@key4-front-library/core/Bo/Components/ListItem";
import { List, ListItemButton, ListItemIcon, ListItemText, Menu, MenuItem, useTheme } from "@mui/material";
import { useIdentity } from "@mykey4/core";

interface Props {
	profiles: Array<DtoProfile>;
	handleOpenModalForm: (isNew: boolean, nameAndId?: DtoProfile) => void;
	handleDelete: (id: string) => void;
}
const ProfileList = (props: Props) => {
	const { profiles, handleDelete, handleOpenModalForm } = props;

	const ref = useRef(null);
	const theme = useTheme();
	const identity = useIdentity();

	const translations = {
		buttons: {
			delete: t("old.form.buttons.delete"),
		},
	};

	const [open, setOpen] = useState<boolean>(false);
	const [anchorEl, setAnchorEl] = useState<HTMLElement | undefined>(undefined);
	const [currentNameAndId, setCurrentNameAndId] = useState<DtoProfile | null>(null);

	useClickOutside(ref, () => {
		setOpen(false);
	});

	const handleMenuItemsOpen = (event: React.MouseEvent<HTMLElement>, _profile: DtoProfile) => {
		setCurrentNameAndId(_profile);
		setAnchorEl(event.currentTarget);
		setOpen(true);
	};

	const handleMenuItemsClick = () => {
		handleDelete(currentNameAndId?.id!);
	};

	const renderNameAndId = (_profile: DtoProfile) => {
		return (
			<ListItem
				key={_profile.id}
				onClick={(event: React.MouseEvent<HTMLElement>) => {
					handleMenuItemsOpen(event, _profile);
				}}
			>
				<ListItemButton
					onClick={() => {
						handleOpenModalForm(false, _profile);
					}}
					key={`list-item-button-${_profile.id}`}
					disableRipple
					disableGutters
					sx={{
						":hover": {
							bgcolor: ApplicationColors.transparent.main,
						},
					}}
				>
					<ListItemText
						primary={_profile.name}
						sx={{
							color: identity.darkmode ? ApplicationColors.white.main : ApplicationColors.blue.light,
							fontSize: "21px",
						}}
					/>
				</ListItemButton>
			</ListItem>
		);
	};

	// TODO Component '@BO/MenuItem'
	return (
		<>
			<List
				sx={{
					"& li.MuiListItem-root": {
						marginBottom: "15px",
						"&:last-child": { marginBottom: "0px" },
					},
				}}
			>
				{profiles.map(renderNameAndId)}
			</List>
			<Menu open={open} anchorEl={anchorEl}>
				<MenuItem ref={ref} disableRipple onClick={handleMenuItemsClick}>
					<ListItemIcon aria-label="delete">
						<Icon prefix="far" iconName={"trash-can"} color={theme.palette.secondary.main} size="1x" />
					</ListItemIcon>
					<ListItemText>{translations.buttons.delete}</ListItemText>
				</MenuItem>
			</Menu>
		</>
	);
};

export default ProfileList;
