import { Icon, type TabProps } from "@mykey4/core";
import { t } from "i18next";

export const settingsTabs = (): Array<TabProps> => [
	{
		label: t("registration.settings.tabs.mailTemplates"),
		to: "mailTemplates",
		icon: <Icon name="envelope" />,
	},
];
