import { ButtonCancel, ButtonSave } from "@infrastructure/components/interface/uikit/K4Button";
import { Box, type Breakpoint, Dialog, DialogActions, DialogContent, DialogTitle, Stack } from "@mui/material";
import { t } from "i18next";

interface Props {
	title?: string;
	children: JSX.Element;
	isOpen: boolean;
	maxWidth?: Breakpoint;
	isFullWidth?: boolean;
	handleModaleClose: () => void;
	handleSubmit: () => void;
}

const SaveModal = (props: Props) => {
	const { title, children, isOpen = false, maxWidth = "md", isFullWidth = true, handleModaleClose, handleSubmit } = props;

	const translations = {
		buttons: {
			save: t("old.form.buttons.save"),
			cancel: t("old.form.buttons.cancel"),
		},
	};

	return (
		<Dialog open={isOpen} maxWidth={maxWidth} fullWidth={isFullWidth}>
			{title && <DialogTitle>{title}</DialogTitle>}
			<DialogContent>
				<Box
					sx={{
						display: "flex",
						justifyContent: "center",
					}}
				>
					{children}
				</Box>
			</DialogContent>
			<DialogActions>
				<Stack direction={"row"} spacing=".8rem" sx={{ px: "2.5rem", pb: "1rem" }}>
					<ButtonCancel label={translations.buttons.cancel} onClick={handleModaleClose} />
					<ButtonSave label={translations.buttons.save} onClick={handleSubmit} />
				</Stack>
			</DialogActions>
		</Dialog>
	);
};
export default SaveModal;
