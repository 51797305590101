import { Tab } from "@mui/material";
import { TabsLayout, Typography, useTabsRoute } from "@mykey4/core";
import { settingsTabs } from "@pages";
import { t } from "i18next";
import { uniqueId } from "lodash";
import { Outlet } from "react-router-dom";

export const Settings = () => {
	const tabs = settingsTabs();
	const { active } = useTabsRoute(tabs.map((tab) => tab.to) ?? []);

	return (
		<TabsLayout
			pt={5}
			spacing={3}
			title={{
				children: <Typography variant="h4">{t("registration.advancedSettings.title")}</Typography>,
			}}
			active={active}
			tabs={tabs.map((tab) => <Tab key={uniqueId("tab-")} {...tab} />)}
		>
			<Outlet />
		</TabsLayout>
	);
};
