import { yupResolver } from "@hookform/resolvers/yup";
import { ButtonCancel, ButtonSave } from "@infrastructure/components/interface/uikit/K4Button";
import FormFieldRule from "@infrastructure/services/i18n/i18nFormRulesParser";
import type { DtoProfile } from "@key4-front-library/core";
import { DialogActions, Stack, TextField } from "@mui/material";
import { t } from "i18next";
import { useForm } from "react-hook-form";
import * as yup from "yup";

type Props = {
	isNew: boolean;
	profile: DtoProfile | undefined;
	handleSaveName: (isNew: boolean, profile: DtoProfile) => void;
	handleModaleClose: () => void;
};

const ProfileEditionForm = (props: Props) => {
	const { isNew, handleSaveName, handleModaleClose, profile } = props;

	const formfields = {
		name: t("old.registration.settings.profiles.edition.form.name.label"),
	};

	const form = {
		name: {
			label: formfields.name,
		},
		buttons: {
			save: t("old.form.buttons.save"),
			cancel: t("old.form.buttons.cancel"),
		},
	};

	const Schema: any = yup.object({
		name: yup.string().required(FormFieldRule.Required(formfields.name)).max(125, FormFieldRule.Max(125, formfields.name)),
	});

	const {
		register,
		handleSubmit,
		formState: { isSubmitting, isDirty, isValid, errors },
	} = useForm<any>({
		resolver: yupResolver(Schema),
		defaultValues: profile,
		mode: "onChange",
	});

	const onSubmit = (_profile: DtoProfile) => {
		handleSaveName(isNew, _profile);
	};

	return (
		<Stack spacing={3} mt={1}>
			<TextField
				id="name"
				aria-label="name"
				{...register("name")}
				label={form.name.label}
				placeholder={form.name.label}
				helperText={<>{errors.name?.message}</>}
				error={!!errors.name}
				fullWidth
			/>
			<DialogActions>
				<ButtonCancel label={form.buttons.cancel} onClick={handleModaleClose} sx={{ mr: 1 }} />
				<ButtonSave label={form.buttons.save} disabled={!isDirty || !isValid || isSubmitting} onClick={handleSubmit(onSubmit)} />
			</DialogActions>
		</Stack>
	);
};

export default ProfileEditionForm;
