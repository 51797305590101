import { metaEnv } from "@mykey4/core";
import { User } from "oidc-client-ts";

const getToken = (): string => {
	const authority = metaEnv.oidc.authority;
	const clientId = metaEnv.oidc.clientId;
	const storageKey = `oidc.user:${authority}:${clientId}`;
	const oidcStorageData = sessionStorage.getItem(storageKey);
	if (!oidcStorageData) {
		return "";
	}
	const user = User.fromStorageString(oidcStorageData);
	return user.access_token;
};

export { getToken };
