import { Box, type Breakpoint, Dialog, DialogContent, DialogTitle } from "@mui/material";

interface Props {
	title?: string;
	children: JSX.Element;
	open: boolean;
	maxWidth?: Breakpoint;
	isFullWidth?: boolean;
}

const DefaultModal = (props: Props) => {
	const { title, children, open = false, maxWidth = "md", isFullWidth = true } = props;

	return (
		<Dialog open={open} maxWidth={maxWidth} fullWidth={isFullWidth}>
			{title && <DialogTitle>{title}</DialogTitle>}
			<DialogContent>
				<Box>{children}</Box>
			</DialogContent>
		</Dialog>
	);
};
export default DefaultModal;
