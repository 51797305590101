import type { ITagCard } from "@domain/model/tags.model";
import FieldLabel from "@infrastructure/components/interface/titles/FieldLabel";
import { pickTextColorBasedOnBgColor } from "@infrastructure/services/color/color.service";
import { Box, Chip } from "@mui/material";
import { t } from "i18next";

type Props = {
	rolesByType: any;
};

const Chips = (props: Props) => {
	const { rolesByType } = props;
	const styles = {
		labelEllipsis: {
			whiteSpace: "nowrap",
			overflow: "hidden",
			textOverflow: "ellipsis",
		},
	};

	const pageResources = {
		programme: t("old.registration.settings.roles.index.programme"),
		manual: t("old.registration.settings.roles.index.manual"),
		external: t("old.registration.settings.roles.index.external"),
	};

	let roleType;
	switch (rolesByType[0]?.type) {
		case "manual":
			roleType = pageResources.manual;
			break;
		case "programme":
			roleType = pageResources.programme;
			break;
		case "external":
			roleType = pageResources.external;
			break;
		default:
			break;
	}

	return (
		<Box>
			{roleType && <FieldLabel label={roleType} />}
			<Box sx={{ pt: 0.5 }}>
				{rolesByType.map((role: ITagCard) => {
					return (
						<Chip
							key={role.id}
							label={role.name}
							style={{ backgroundColor: role.color }}
							sx={{
								mr: 0.375,
								mb: 0.625,
								"& .MuiChip-label": {
									color: pickTextColorBasedOnBgColor(role.color).textColor,
									...styles.labelEllipsis,
								},
								maxWidth: "100%",
							}}
							size="small"
						/>
					);
				})}
			</Box>
		</Box>
	);
};

export default Chips;
