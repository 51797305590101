import { Stack } from "@mui/material";
import type { SxProps } from "@mui/system";

import K4StatusBullet from "./K4StatusBullet";

interface IK4StatusBulletLegend {
	text: string;
	bullet: {
		color: string;
		size?: string;
		sx?: SxProps;
	};
}

const K4StatusBulletLegend = (props: IK4StatusBulletLegend) => {
	const { text, bullet } = props;
	return (
		<Stack spacing={1} direction="row">
			<K4StatusBullet color={bullet.color} size={bullet.size} sx={bullet.sx} />
			<span style={{ paddingTop: "1px" }}>{text}</span>
		</Stack>
	);
};

export default K4StatusBulletLegend;
