import useDebounce from "@infrastructure/services/hooks/useDebounce";
import { IconButton, InputBase, Paper } from "@mui/material";
import { t } from "i18next";
import { useEffect, useState } from "react";
import { Icon } from "../icons/Icon";

interface Props {
	handleChange: (value: string) => void;
}

const SimpleTextSearchFilter = (props: Props) => {
	const { handleChange } = props;
	const [searchValue, setSearchValue] = useState("");
	const debouncedSearchValue = useDebounce<string | undefined>(searchValue, 250);

	useEffect(() => {
		handleChange(searchValue);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [debouncedSearchValue]);

	const formfields = { search: t("old.common.filters.search.label") };

	const pageResources = {
		search: {
			label: formfields.search,
		},
		buttons: {
			search: t("old.form.buttons.search"),
			reset: t("old.form.buttons.reset"),
		},
	};

	return (
		<Paper
			variant="outlined"
			sx={{
				p: "2px 4px",
				display: "flex",
				alignItems: "center",
			}}
		>
			<InputBase
				onChange={(e) => {
					setSearchValue(e.target.value);
				}}
				sx={{ ml: 1, flex: 1 }}
				placeholder={pageResources.search.label}
				inputProps={{
					"aria-label": "search-input",
				}}
				value={searchValue}
				fullWidth
			/>

			<IconButton
				sx={{ p: "10px" }}
				aria-label="reset-search"
				color={"secondary"}
				onClick={() => {
					setSearchValue("");
					handleChange("");
				}}
			>
				<Icon prefix="far" iconName="xmark" />
			</IconButton>
		</Paper>
	);
};

export default SimpleTextSearchFilter;
