import { getLibraryCoreTranslation } from "@key4-front-library/core";
import type { ModuleTranslation } from "@mykey4/core";
import en from "./Languages/en.json";
import fr from "./Languages/fr.json";

export const getRegistrationTranslation = (): ModuleTranslation => {
	const core = getLibraryCoreTranslation();
	return {
		en: {
			...core.en,
			...en,
		},
		fr: {
			...core.fr,
			...fr,
		},
	};
};
