import { forIn, trim } from "lodash";

import type { TypeCustomFieldValues, TypeUseFormCustomFieldValue, TypeUseFormCustomFieldsValues } from "@key4-front-library/core";
import { EnumCustomFieldKind } from "@key4-front-library/core/Enums";

// Remap custom field part useform to POST or PUT data part
const useFormToDataAPI = (customFieldValues: TypeUseFormCustomFieldsValues | undefined): TypeCustomFieldValues => {
	const result: TypeCustomFieldValues = {};
	if (customFieldValues) {
		forIn(customFieldValues, (customField: TypeUseFormCustomFieldValue, key: string) => {
			if (!result || !customField) {
				return;
			}

			let val: string | Array<string> | null | undefined | boolean =
				typeof customField.value === "string" && trim(customField.value) === "" ? null : customField.value;
			if (customField.kind === EnumCustomFieldKind.RADIO) {
				if (typeof val === "string") {
					val = [val];
				} else {
					val = [];
				}
			}

			result[key] = {
				isCustomized: customField.customDefault.isCustomized,
				customizedValue: customField.customDefault.isCustomized ? val : customField.customDefault.customizedValue,
				value: customField.customDefault.isCustomized ? customField.customDefault.value : val,
			};
		});
	}

	return result;
};

const CustomFieldHelper = {
	useFormToDataAPI,
};

export default CustomFieldHelper;
