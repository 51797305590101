import ParticipantDetailInvitations from "@application/Components/participants/details/tabsView/ParticipantsDetailInvitations";
import { TabParticipantMails } from "@application/Components/participants/details/tabsView/mails/TabParticipantMails";
import { ModuleErrorTemplate, ModuleSpace } from "@components";
import {
	CFEditorCustomField,
	CustomFieldDefinition,
	EnumCustomFieldScope,
	MailTemplate,
	MAIN_TAB,
	NotificationProvider,
	OutletModule,
	Redirection,
	UsersPage,
} from "@key4-front-library/core";
import ProvidersCore from "@key4-front-library/core/Providers";
import { useTheme } from "@mui/material";
import { ErrorTemplate, getErrorTemplate } from "@mykey4/core";
import { AdvancedSettings, Emailing, ParticipantDetails, ParticipantGridLabelsTab, Profiles, Roles, Settings } from "@pages";
import BulkActionParticipant from "@presentation/participants/BulkActionParticipants";
import PageParticipants from "@presentation/participants/PageParticipants";
import { Navigate, Route, Routes as RoutesDom } from "react-router-dom";

export const Routes = (): React.ReactElement => {
	const mode = useTheme().palette.mode;

	return (
		<RoutesDom>
			<Route path={"/"}>
				<Route
					path={""}
					element={
						<ProvidersCore.ProviderModule>
							<Redirection />
						</ProvidersCore.ProviderModule>
					}
				/>
				<Route path={"auth"}>
					<Route path={"login"} element={<Navigate replace to={localStorage.getItem("redirectUri") ?? ""} />} />
					<Route path={"logout"} element={<Navigate replace to={"/"} />} />
				</Route>
				{/* ERROR section */}
				<Route path={"400"} element={<ErrorTemplate {...getErrorTemplate({ type: "400", options: { mode } })} />} />
				<Route path={"401"} element={<ErrorTemplate {...getErrorTemplate({ type: "401", options: { mode } })} />} />
				<Route path={"403"} element={<ErrorTemplate {...getErrorTemplate({ type: "403", options: { mode } })} />} />
				<Route path={"404"} element={<ErrorTemplate {...getErrorTemplate({ type: "404", options: { mode } })} />} />
				<Route path={"500"} element={<ErrorTemplate {...getErrorTemplate({ type: "500", options: { mode } })} />} />
				<Route
					path={":client/:event"}
					element={
						<ProvidersCore.ProviderModule>
							<OutletModule />
						</ProvidersCore.ProviderModule>
					}
				>
					<Route
						element={
							<NotificationProvider>
								<ModuleSpace />
							</NotificationProvider>
						}
					>
						<Route index element={<Navigate replace to={"participants"} />} />
						<Route path={"participants"}>
							<Route
								index
								element={
									<CustomFieldDefinition scope={[EnumCustomFieldScope.PARTICIPANT]}>
										<PageParticipants />
									</CustomFieldDefinition>
								}
							/>
							{/* Le empty est géré directement dans ParticipantDetails */}
							<Route
								path=":participantId"
								element={
									<CustomFieldDefinition scope={[EnumCustomFieldScope.PARTICIPANT]}>
										<ParticipantDetails />
									</CustomFieldDefinition>
								}
							>
								<Route path="" element={<Navigate replace to={MAIN_TAB} />} />
								<Route path="*" element={<Navigate replace to={MAIN_TAB} />} />
								<Route path={MAIN_TAB} element={<ParticipantGridLabelsTab />} />
								<Route path={"mail"} element={<TabParticipantMails />} />
								{/* TODO à réactiver pour ERS1 */}
								{/* <Route path={"privileges"} element={<ParticipantDetailsPrivileges />} /> */}
								<Route path={"faculty"} element={<ParticipantDetailInvitations />} />
							</Route>
							<Route path={"bulkaction"}>
								<Route path={":activeStep"} element={<BulkActionParticipant />} />
							</Route>
						</Route>
						{/* TODO à réactiver pour ERS1 */}
						{/* <Route path={"privileges"}>
							<Route path={"analytics"} element={<PagePrivilegesAnalytics />} />
							<Route path={"matrix"}>
								<Route path={""} element={<PagePrivilegesMatrix />} />
								<Route path={":privilegeId"} element={<PagePrivilegeDetails />} />
							</Route>
						</Route> */}
						<Route path={"settings"} element={<Settings />}>
							<Route index element={<Navigate replace to={"mailTemplates"} />} />
							<Route path={"mailTemplates"} element={<MailTemplate module="registration" targets={["contact", "participant"]} targetModal="participant" />} />
						</Route>
						<Route path={"advancedSettings"} element={<AdvancedSettings />}>
							<Route path={""} element={<Navigate replace to={"roles"} />} />
							{/* TODO à réactiver pour ERS1 */}
							{/* <Route path={"cost_centers"} element={<PageSettingsCostCenters />} /> */}
							<Route path={"roles"} element={<Roles />} />
							<Route path={"profiles"} element={<Profiles />} />
							<Route path={"profiles"} element={<Profiles />} />
							<Route path={"emailing"} element={<Emailing />} />
						</Route>
						<Route path={"users"} element={<UsersPage />} />
						<Route path={"formeditor"} element={<CFEditorCustomField />} />
					</Route>
					<Route path={"*"} element={<ModuleErrorTemplate />} />
				</Route>
				<Route path={"*"} element={<ErrorTemplate to={"/"} {...getErrorTemplate({ type: "404", options: { mode } })} />} />
			</Route>
		</RoutesDom>
	);
};
