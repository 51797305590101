import { useParticipant } from "@api";
import { GeneralSectionParticipant } from "@components";
import { GridLabelsTabCustomField, useContextModule } from "@key4-front-library/core";
import { Stack } from "@mykey4/core";
import { useParams } from "react-router-dom";

export const ParticipantGridLabelsTab = (): React.ReactNode => {
	const { participantId = "" } = useParams();
	const { client, event } = useContextModule();
	const participantQuery = useParticipant(client.id, event.id, participantId, { queryStrings: [{ key: "includeCustomFields", value: "true" }] });

	if (!participantQuery.data) {
		return undefined;
	}

	return (
		<Stack spacing={2}>
			<GeneralSectionParticipant />
			<GridLabelsTabCustomField module="registration" scope="participant" id={participantId} customFieldValues={participantQuery.data.customFieldValues} />
		</Stack>
	);
};
